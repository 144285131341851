import { Module } from "vuex";
import { RootState } from "@/store/types";
import { getters } from "@/store/modules/find-devices/getters";
import { mutations } from "@/store/modules/find-devices/mutations";
import { actions } from "@/store/modules/find-devices/actions";
import { FindDevicesState } from "@/store/modules/find-devices/types";

export const Data: FindDevicesState = {
  devicesList: {},
  devicesDumpList: {},
  showAssignToPatient: false,
  showAssignToClinician: false,
  showMarkAsDirty: false,
  showMarkAsAvailable: false,
  showSubmitRepairTicket: false,
  currentDevice: null,
  selectedItem: {
    type: "",
    name: "",
    devices: null,
  },
  selectedDevice: null,
  tagOption: "all",
};

export const findDevices: Module<FindDevicesState, RootState> = {
  namespaced: true,
  state: Data,
  getters,
  actions,
  mutations,
};

export default findDevices;
