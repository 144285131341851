<template>
  <div>
    <div class="item__list__title">
      <h5 v-if="title" class="modal__content__sub__title">{{ title }}</h5>
      <button @click="toggle">
        <BaseIcon :icon="`expand-${open ? 'less' : 'more'}`" />
      </button>
    </div>
    <div v-if="open" class="modal__table">
      <slot />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";

export default {
  name: "ItemsList",
  components: { BaseIcon },
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup() {
    const open = ref(true);

    const toggle = () => {
      open.value = !open.value;
    };

    return { open, toggle };
  },
};
</script>

<style scoped></style>
