<template>
  <div class="modals ble__update__voltage">
    <div v-if="!voltageForm" class="ble__update__voltage__container">
      <span
        >Report Date:
        {{
          device?.beacon?.battery_report_date
            ? formatDate2(device.beacon.battery_report_date)
            : "N/A"
        }}</span
      >
      <button
        v-if="device?.beacon?.id"
        @click.stop="toggleForm"
        class="focused"
      >
        Update Voltage
      </button>
    </div>
    <form
      @submit.prevent="updateVoltage"
      v-else
      class="ble__update__voltage__container"
    >
      <input
        placeholder="Battery Voltage"
        class="base__input"
        type="number"
        v-model="voltage"
        required
        step="0.01"
        @focus="isInputFocused = true"
        @blur="isInputFocused = false"
      />
      <button :disabled="disabled" :class="{ focused: isInputFocused }">
        <template v-if="!disabled">Update Voltage</template>
        <BaseIcon v-else class="loader" icon="loader/spinner" />
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import store from "@/store";
import { helpers } from "@/composables/helpers";
import BaseIcon from "@/components/svg/BaseIcon.vue";

export default defineComponent({
  name: "UpdateBeaconVoltage",
  components: { BaseIcon },
  setup() {
    const { formatDate2 } = helpers();

    let voltageOpen = ref(true);
    const disabled = ref(false);
    let voltageForm = ref(false);
    const voltage = ref("");
    const isInputFocused = ref(false);

    const toggleVoltageModal = () => {
      voltageOpen.value = !voltageOpen.value;
    };

    const device = computed(() => {
      return store.getters["devices/device"];
    });

    const toggleForm = () => {
      voltageForm.value = !voltageForm.value;
    };
    const updateVoltage = () => {
      const data = {
        beaconId: device.value.beacon?.id,
        voltage: voltage.value,
      };

      if (!data.beaconId) return;

      disabled.value = true;
      store
        .dispatch("devices/updateDeviceBeaconVoltage", data)
        .then((res) => {
          disabled.value = false;
          device.value.beacon = res;
          toggleForm();
        })
        .catch(() => {
          disabled.value = false;
        });
    };

    return {
      voltage,
      device,
      formatDate2,
      isInputFocused,
      voltageOpen,
      voltageForm,
      toggleVoltageModal,
      toggleForm,
      updateVoltage,
      disabled,
    };
  },
});
</script>
