import { Module } from "vuex";
import { RootState } from "@/store/types";
import { getters } from "@/store/modules/dropdowns/getters";
import { mutations } from "@/store/modules/dropdowns/mutations";
import { actions } from "@/store/modules/dropdowns/actions";
import { DropdownState } from "@/store/modules/dropdowns/types";

const state: DropdownState = {
  header_user: false,
  header_notification: false,
  filters: false,
  access_points_list: false,
  update_beacon_voltage: false,
  access_point_list_item: -1,
  device_location_list_item: -1,
};

export const dropdowns: Module<DropdownState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default dropdowns;
