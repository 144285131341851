import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "modals ble__update__voltage" }
const _hoisted_2 = {
  key: 0,
  class: "ble__update__voltage__container"
}
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.voltageForm)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", null, "Report Date: " + _toDisplayString(_ctx.device?.beacon?.battery_report_date
            ? _ctx.formatDate2(_ctx.device.beacon.battery_report_date)
            : "N/A"), 1),
          (_ctx.device?.beacon?.id)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleForm && _ctx.toggleForm(...args)), ["stop"])),
                class: "focused"
              }, " Update Voltage "))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("form", {
          key: 1,
          onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateVoltage && _ctx.updateVoltage(...args)), ["prevent"])),
          class: "ble__update__voltage__container"
        }, [
          _withDirectives(_createElementVNode("input", {
            placeholder: "Battery Voltage",
            class: "base__input",
            type: "number",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.voltage) = $event)),
            required: "",
            step: "0.01",
            onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isInputFocused = true)),
            onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isInputFocused = false))
          }, null, 544), [
            [_vModelText, _ctx.voltage]
          ]),
          _createElementVNode("button", {
            disabled: _ctx.disabled,
            class: _normalizeClass({ focused: _ctx.isInputFocused })
          }, [
            (!_ctx.disabled)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode("Update Voltage")
                ], 64))
              : (_openBlock(), _createBlock(_component_BaseIcon, {
                  key: 1,
                  class: "loader",
                  icon: "loader/spinner"
                }))
          ], 10, _hoisted_3)
        ], 32))
  ]))
}