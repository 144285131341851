import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a341bfd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input__group__list__search" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseInput = _resolveComponent("BaseInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseIcon, {
      class: "input__group__list__search__svg",
      icon: "form/search"
    }),
    _createVNode(_component_BaseInput, {
      modelValue: _ctx.searchString,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchString) = $event)),
      placeholder: "Search by Serial No and BIOMED ID",
      type: "text",
      class: "input__group__list__search__input"
    }, null, 8, ["modelValue"])
  ]))
}