<template>
  <BaseLayout>
    <Filters
      @apply_filters="changeData"
      :show-search="true"
      :allowedFilters="allowedFilters"
    />

    <div v-if="!searchString" class="devices dashboard__card">
      <div class="modal__tabs">
        <button
          @click="setGraph(graph)"
          :class="{ active: activeGraph === graph }"
          v-for="(graph, key) in graphs"
          :key="key"
        >
          {{ graph }}
        </button>
      </div>

      <div class="flex">
        <div
          class="devices__chart"
          :class="{ recon: activeGraph === RECONCILIATION }"
        >
          <div class="doughnut__chart">
            <DevicesStatusesChart v-if="activeGraph === graphs[0]" />
            <DevicesReconciliationStatusesChart
              v-if="activeGraph === graphs[1]"
            />
          </div>
        </div>

        <DeviceStatCards v-if="activeGraph === graphs[0]" />
      </div>
    </div>

    <!--    <DevicesIssues />-->

    <TableComponent
      v-if="devices.data && filteredFields"
      ref="tableRef"
      @open="openDevice"
      @updatePagination="updateTable"
      name="Devices"
      :fields="filteredFields"
      :data="devices"
      :has-search-empty="!!(devices.data.length === 0 && searchString)"
      :computeStatus="computeStatus"
    >
      <div class="table__search__container">
        <div class="table__search empty">
          <p>Sorry, no results for “{{ searchString }}” were found</p>
          <h6 @click="searchWithoutFilters">Search without filters</h6>
        </div>
      </div>
    </TableComponent>

    <div class="view__bg">
      <router-view @close="closeDevice" />
    </div>
  </BaseLayout>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import BaseLayout from "@/components/layout/BaseLayout.vue";
import TableComponent from "@/components/resusables/table/Table.vue";
import Filters from "@/components/resusables/filters/Filters.vue";
import DeviceStatCards from "../../components/devices/StatCards.vue";

import router from "@/router";
import store from "@/store";
import { FilterScopeEnums } from "@/store/modules/filters/types";
import DevicesStatusesChart from "@/components/devices/charts/DevicesStatusesChart";
import DevicesReconciliationStatusesChart from "@/components/devices/charts/DevicesReconciliationStatusesChart";

export default defineComponent({
  name: "DevicesPage",
  components: {
    DevicesReconciliationStatusesChart,
    DevicesStatusesChart,
    DeviceStatCards,
    TableComponent,
    Filters,
    BaseLayout,
  },

  methods: {
    changeData(filter) {
      this.$refs.tableRef?.resetSorting();

      store.dispatch("devices/getDevices");
      store.dispatch("devices/getStatusesCount");
      store.dispatch("analytics/getDevicesReconciliationCount");
      store.dispatch("contracts/getAllContracts");
      // store.dispatch("devices/getReconciliationStatusesCount");
      store.dispatch("analytics/getFinancialData", { filter });
    },
  },

  setup() {
    const isOpen = ref(false);
    const dataIndex = ref(0);
    const UTILIZATION = "utilization";
    const RECONCILIATION = "reconciliation";
    const graphs = ["utilization", "reconciliation"];
    const activeGraph = ref(graphs[0]);

    const allowedFilters = [
      FilterScopeEnums.SUBCATEGORY,
      FilterScopeEnums.VENDOR,
      FilterScopeEnums.RECONCILIATION_STATUSES,
      FilterScopeEnums.DEVICE_STATUSES,
      FilterScopeEnums.DEVICE_SEARCH,
    ];

    const filterFields = (group) => {
      return fields.filter((field) => !field.group || field.group === group);
    };

    const fields = [
      {
        key: "serial_number",
        label: "Serial No",
        sortable: true,
        order_key: "serial_number",
        group: null,
      },
      {
        key: "status",
        label: "Utilization Status",
        type: "status",
        sortable: true,
        order_key: "status",
        group: UTILIZATION,
      },
      {
        key: "reconciliation_status",
        label: "Reconciliation Status",
        sortable: true,
        order_key: "reconciliation_status",
        group: RECONCILIATION,
      },
      {
        key: ["subcategory", "category", "name"],
        label: "Category",
        type: "object",
        sortable: true,
        order_key: "category",
        group: null,
      },
      {
        key: ["subcategory", "name"],
        label: "Sub Category",
        type: "object",
        sortable: true,
        order_key: "subcategory",
        group: null,
      },
      {
        key: ["vendor", "company_name"],
        type: "object",
        label: "Vendor",
        sortable: true,
        order_key: "vendor",
        group: null,
      },
      {
        key: "contract",
        type: "contract",
        label: "Contracts",
        sortable: false,
        group: UTILIZATION,
      },
      {
        key: "orders",
        order_type: "purchase",
        type: "order_type",
        label: "Purchase",
        sortable: false,
        group: UTILIZATION,
      },
      {
        key: "orders",
        order_type: "maintenance",
        type: "device_service",
        label: "Service",
        sortable: false,
        group: UTILIZATION,
      },
      {
        key: "orders",
        order_type: "rental",
        type: "order_type",
        label: "Rental",
        sortable: false,
        group: UTILIZATION,
      },
      {
        key: "orders",
        order_type: "preventative_maintenance",
        type: "order_type",
        label: "Maintenance",
        sortable: false,
        group: UTILIZATION,
      },
      {
        key: "total_costs",
        type: "total_orders",
        label: "Total Costs",
        sortable: false,
        group: UTILIZATION,
      },
      {
        key: "last_seen",
        type: "date",
        label: "Last Seen Date",
        sortable: true,
        order_key: "last_seen",
        group: null,
      },
    ];

    const filteredFields = ref(null);

    const devices = computed(() => {
      return store.getters["devices/devices"];
    });

    const searchString = computed(() => store.getters["filters/searchString"]);

    const computeStatus = (row) => {
      return row.status;
    };

    onMounted(() => {
      getData();
      setGraph(graphs[0]);
    });

    watch(searchString, () => {
      store.dispatch("devices/getDevices");
    });

    const searchWithoutFilters = () => {
      store.commit("filters/CLEAR_FILTERS");
      store.dispatch("devices/getDevices");
      store.dispatch("devices/getStatusesCount");
      store.dispatch("analytics/getDevicesReconciliationCount");
      store.dispatch("contracts/getAllContracts");
    };

    const openDevice = (device) => {
      isOpen.value = true;
      store.commit("devices/SET_DEVICE", device);
      router.push({ name: "ViewDevice", params: { id: device.id } });
    };

    const closeDevice = () => {
      isOpen.value = false;
      router.push({ name: "Devices" });
    };

    const setGraph = (graph) => {
      if (graph === UTILIZATION) {
        filteredFields.value = fields.filter(
          (field) => !field.group || field.group === UTILIZATION
        );
      } else if (graph === RECONCILIATION) {
        filteredFields.value = fields.filter(
          (field) => !field.group || field.group === RECONCILIATION
        );
      }
      activeGraph.value = graph;
    };

    const getData = () => {
      store.dispatch("devices/getDevices");
      store.dispatch("devices/getHospitals");
      store.dispatch("devices/getDepartments");
      store.dispatch("devices/getVendors");
      store.dispatch("contracts/getAllContracts");
    };

    const updateTable = (res) => {
      store.dispatch("devices/getDevices", res);
    };

    return {
      isOpen,
      searchString,
      UTILIZATION,
      RECONCILIATION,
      computeStatus,
      openDevice,
      closeDevice,
      updateTable,
      devices,
      fields,
      filteredFields,
      dataIndex,
      allowedFilters,
      setGraph,
      graphs,
      activeGraph,
      filterFields,
      searchWithoutFilters,
    };
  },
});
</script>
