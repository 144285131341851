import { MutationTree } from "vuex";
import {
  FindDevicesState,
  SelectedItemOnMap,
} from "@/store/modules/find-devices/types";

export const mutations: MutationTree<FindDevicesState> = {
  SET_DEVICES_LIST(state: FindDevicesState, data: object) {
    state.devicesList = data;
    return state.devicesList;
  },
  SET_DEVICES_DUMP_LIST(state: FindDevicesState, data: object) {
    state.devicesDumpList = data;
    return state.devicesDumpList;
  },
  SET_SHOW_ASSIGN_TO_PATIENT(state: FindDevicesState, data: boolean) {
    state.showAssignToPatient = data;
    return state.showAssignToPatient;
  },
  SET_SHOW_ASSIGN_TO_CLINICIAN(state: FindDevicesState, data: boolean) {
    state.showAssignToClinician = data;
    return state.showAssignToClinician;
  },
  SET_SHOW_MARK_AS_DIRTY(state: FindDevicesState, data: boolean) {
    state.showMarkAsDirty = data;
    return state.showMarkAsDirty;
  },
  SET_SHOW_MARK_AS_AVAILABLE(state: FindDevicesState, data: boolean) {
    state.showMarkAsAvailable = data;
    return state.showMarkAsAvailable;
  },
  SET_SHOW_SUBMIT_REPAIR_TICKET(state: FindDevicesState, data: boolean) {
    state.showSubmitRepairTicket = data;
    return state.showSubmitRepairTicket;
  },
  SET_TAG_OPTION(state: FindDevicesState, data: string) {
    state.tagOption = data;
    return state.tagOption;
  },
  SET_MODAL(state: FindDevicesState, { modal, show }) {
    state[modal] = show;
    return state[modal];
  },
  CLOSE_ALL_MODALS(state: FindDevicesState) {
    state.showAssignToPatient = false;
    state.showAssignToClinician = false;
    state.showMarkAsDirty = false;
    state.showMarkAsAvailable = false;
    state.showSubmitRepairTicket = false;
    // state.currentDevice = null;
    return state;
  },
  SET_CURRENT_DEVICE(state: FindDevicesState, data: object) {
    state.currentDevice = data;
    return state.currentDevice;
  },
  SET_SELECTED_ITEM(state: FindDevicesState, data: SelectedItemOnMap) {
    if (
      state.selectedItem.type === data.type &&
      state.selectedItem.name === data.name
    ) {
      state.selectedItem = {
        type: "",
        name: "",
        devices: null,
      };
    } else {
      state.selectedItem = data;
      state.selectedDevice = null;
    }
    return state.selectedItem;
  },
  RESET_SELECTED_ITEM(state: FindDevicesState) {
    state.selectedItem = {
      type: "",
      name: "",
      devices: null,
    };
    return state.selectedItem;
  },
  SET_SELECTED_DEVICE(state: FindDevicesState, data) {
    state.selectedDevice = data;
    state.selectedItem = {
      type: "",
      name: "",
      devices: null,
    };
    return state.selectedDevice;
  },
};
