export function formatChartsData() {
  const formatDoughnutData = (data) => {
    return {
      datasets: [
        {
          data: [
            data["available"],
            data["unavailable"],
            data["in_use"] + data["dirty"],
            data["in_repair"],
          ],
          backgroundColor: [
            "#016667",
            "#DAB75D",
            "#B5CBCB",
            "#DAE5E6",
            "#DAE5E6",
          ],
        },
      ],
      labels: ["available", "unavailable", "in_use", "in_repair"],
    };
  };

  const formatLineChartData = (data) => {
    const arraySum = data.in_use.map((num, idx) => {
      return num + data.dirty[idx];
    });

    return {
      labels: data.labels,
      datasets: [
        {
          label: "Available",
          data: data.available,
          borderColor: "#016667",
          backgroundColor: "#016667",
          yAxisID: "y",
        },
        {
          label: "Unavailable",
          data: data.unavailable,
          borderColor: "#DAB75D",
          backgroundColor: "#DAB75D",
          yAxisID: "y",
        },
        {
          label: "In use",
          data: arraySum,
          borderColor: "#B5CBCB",
          backgroundColor: "#B5CBCB",
          yAxisID: "y",
        },
        {
          label: "In repair",
          data: data.in_repair,
          borderColor: "#DAE5E6",
          backgroundColor: "#DAE5E6",
          yAxisID: "y",
        },
      ],
    };
  };

  return { formatDoughnutData, formatLineChartData };
}
