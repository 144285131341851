<template>
  <div class="device-view__body__details">
    <div class="device-view__body__details__left">
      <ItemsList title="Current Status">
        <ItemRow title="Status">
          <div v-if="device.status === 'in_use'" class="modal__badge">
            <h6 class="modal__status__badge">
              {{ formatStatus(device.status) }}
            </h6>
          </div>
          <h5 v-else class="modal__table__row__value">
            {{ device.status }}
          </h5>
        </ItemRow>
        <ItemRow title="Location">
          <div v-if="device.location" class="device__location__levels">
            <h6>{{ device.location.building_name }}</h6>
            <BaseIcon icon="greater-than" />
            <h6>{{ device.location.floor_name }}</h6>
            <BaseIcon icon="greater-than" />
            <h6>{{ device.location.room_name }}</h6>
          </div>
        </ItemRow>
        <ItemRow title="Reconciliation Status">
          <div
            v-if="device.reconciliation_status"
            class="device__serial__badge"
            :class="addReconciliationStatusClass(device.reconciliation_status)"
          >
            <BaseIcon
              :icon="`device/reconciliation/${addReconciliationStatusClass(
                device.reconciliation_status
              )}`"
            />
            <span>{{ device.reconciliation_status || "-" }}</span>
          </div>
          <h5 v-else class="modal__table__row__value">-</h5>
        </ItemRow>
      </ItemsList>
      <ItemsList title="Description">
        <ItemRow title="Plate Number" :value="device.plate_id" />
        <ItemRow
          title="Category"
          :value="device?.subcategory?.category?.name"
        />
        <ItemRow title="Subcategory" :value="device?.subcategory?.name" />
        <ItemRow
          title="Department"
          :value="device?.subcategory?.category?.department?.name"
        />
        <ItemRow title="Serial No" :value="device.serial_number" />
        <ItemRow title="BIOMED ID" :value="device.cmms_id" />
        <ItemRow title="GS1 ID" :value="device.gs1_id" />
      </ItemsList>
      <ItemsList title="Vendor Details">
        <ItemRow
          title="Brand Name"
          :value="device?.vendor?.brand_name || null"
        />
        <ItemRow
          title="Company Name"
          :value="device?.vendor?.company_name || null"
        />
        <ItemRow
          title="DUNS Number"
          :value="device?.vendor?.duns_number || null"
        />
        <ItemRow
          title="Support Email"
          :value="device?.vendor?.support_email || null"
        />
        <ItemRow
          title="Support Phone"
          :value="device?.vendor?.support_phone || null"
        />
      </ItemsList>

      <ItemsList title="Associated BLE Tag">
        <ItemRow title="Mac Address" :value="device?.beacon?.mac || null" />
        <ItemRow title="Model" :value="device?.beacon?.name || null" />
        <ItemRow
          title="Date Installed"
          :value="formatDate2(device?.beacon?.install_date)"
        />
        <ItemRow title="Battery Voltage">
          <div class="device__ble__voltage">
            <span>
              {{
                device?.beacon?.voltage ? `${device.beacon.voltage}v` : "N/A"
              }}
            </span>
            <button class="modals-trigger" @click.stop="toggleUpdateVoltage">
              <BaseIcon icon="info" />
            </button>
            <UpdateBeaconVoltage v-if="showUpdateVoltage" />
          </div>
        </ItemRow>
        <ItemRow
          title="Next Battery Replacement Date"
          :value="formatDate2(device?.beacon?.battery_replacement_date)"
        />
      </ItemsList>
    </div>
    <div class="device-view__body__details__right">
      <div class="device__costs">
        <h6 class="modal__content__sub__title">Cost of ownership</h6>

        <div class="device__costs__list">
          <div
            :key="key"
            v-for="(order, key) in orders"
            class="device__costs__list__item"
          >
            <h5 class="device__costs__list__item__title">
              {{ order.name }}
            </h5>
            <h5 class="device__costs__list__item__amount">
              {{ order.amount }}
            </h5>
          </div>
        </div>

        <div class="device__costs__total">
          <h5 class="device__costs__total__title">Total</h5>
          <h5 class="device__costs__total__amount">{{ total }}</h5>
        </div>

        <TableComponent
          name="Documents"
          ref="tableRefs"
          @downloadDeviceDoc="downloadDoc"
          @updatePagination="updateTable"
          :show-title="false"
          :show-column-setting="false"
          :fields="docsFields"
          :data="deviceFiles"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from "vue";
import store from "@/store";
import { viewDevice } from "@/composables/devices/view-device";
import BaseIcon from "@/components/svg/BaseIcon";
import ItemsList from "@/components/resusables/list/ItemsList";
import ItemRow from "@/components/resusables/list/ItemRow";
import TableComponent from "@/components/resusables/table/Table.vue";
import { useRoute } from "vue-router";
import { helpers } from "@/composables/helpers";
import UpdateBeaconVoltage from "@/components/devices/beacon/UpdateBeaconVoltage.vue";

export default defineComponent({
  name: "DeviceDetails",
  components: {
    UpdateBeaconVoltage,
    TableComponent,
    ItemRow,
    ItemsList,
    BaseIcon,
  },

  setup() {
    const isOpen = ref(false);
    const dataIndex = ref(0);
    const { getCostOfOwnership, formatStatus } = viewDevice();
    const { formatDate2 } = helpers();

    let total = ref("");

    let orders = ref([]);

    const route = useRoute();

    const docsFields = [
      {
        key: "file_name",
        type: "file_name",
        label: "File Name",
        sortable: false,
        order_key: "file_name",
      },
      {
        key: "page_number",
        label: "Page No.",
        sortable: false,
      },
      {
        key: "type",
        label: "Type",
        sortable: false,
      },
      {
        key: "start_date",
        type: "date",
        label: "Start Date",
        sortable: false,
      },
      {
        key: "reconciled",
        type: "bool_answer",
        label: "Reconciled",
        sortable: false,
      },
    ];

    onMounted(async () => {
      const orderRes = getCostOfOwnership();
      total.value = orderRes.total;
      orders.value = orderRes.orders;
    });

    const device = computed(() => {
      return store.getters["devices/device"];
    });

    const deviceFiles = computed(() => {
      return store.getters["devices/deviceFiles"];
    });

    const reconciliationEvent = computed(() => {
      return store.getters["devices/reconciliationEvent"];
    });

    const showUpdateVoltage = computed(() => {
      return store.getters["dropdowns/updateBeaconVoltage"];
    });

    const downloadDoc = async (doc) => {
      const res = await store.dispatch("devices/downloadDocument", doc);
      const pdfBlob = new Blob([res], { type: "application/pdf" });
      const blobUrl = window.URL.createObjectURL(pdfBlob);
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = doc.file_name;
      anchor.click();
      window.URL.revokeObjectURL(blobUrl);
    };

    const addReconciliationStatusClass = (value) => {
      if (["registered", "confirmed"].includes(value)) {
        return "success";
      } else if (["undocumented", "unbooked", "rogue"].includes(value)) {
        return "warning";
      } else if (["missing", "phantom"].includes(value)) {
        return "danger";
      }
      return "";
    };

    const updateTable = (res) => {
      const deviceId = route.params.id;
      store.dispatch("devices/getDeviceFiles", {
        device_id: deviceId,
        ...res,
      });
    };

    const toggleUpdateVoltage = () => {
      store.dispatch("dropdowns/toggleDropdown", "update_beacon_voltage");
    };

    return {
      docsFields,
      device,
      deviceFiles,
      formatStatus,
      isOpen,
      total,
      orders,
      updateTable,
      reconciliationEvent,
      addReconciliationStatusClass,
      downloadDoc,
      dataIndex,
      formatDate2,
      toggleUpdateVoltage,
      showUpdateVoltage,
    };
  },
});
</script>
