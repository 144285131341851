<template>
  <BaseLayout>
    <Filters
      @apply_filters="changeData"
      :allowedFilters="allowedFilters"
      :show-date-picker="true"
      @date_updated="dateUpdated"
    />

    <div class="device-view dashboard__card">
      <div class="device-view__navigation">
        <router-link class="device-view__navigation__back" to="/devices">
          <BaseIcon icon="device/back" />
          <span>All Devices</span>
        </router-link>
      </div>
      <div class="device-view__body">
        <div class="device-view__body__description">
          <h6>Description</h6>
          <div class="device-view__body__description__titles">
            <div>
              <h2>{{ device?.subcategory?.category?.name }}</h2>
              <h4>{{ device?.subcategory?.name }}</h4>
            </div>
            <div>
              <h5>
                {{ device?.subcategory?.category?.department?.name }} Department
              </h5>
              <h5><span>Serial No:</span> {{ device.serial_number }}</h5>
            </div>
          </div>
        </div>

        <div class="device-view__navigation__tabs">
          <button
            @click="changeTabIndex(index)"
            :key="index"
            :class="{ active: deviceTabIndex === index }"
            v-for="(tab, index) in deviceViewTabs"
          >
            {{ tab }}
          </button>
        </div>
        <DeviceDetails v-if="deviceTabIndex === 0" />
        <DeviceUtilizationEvents v-if="deviceTabIndex === 1" />
        <DeviceUtilizationSummary v-if="deviceTabIndex === 2" />
      </div>
    </div>
  </BaseLayout>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from "vue";
import BaseLayout from "@/components/layout/BaseLayout.vue";
import Filters from "@/components/resusables/filters/Filters.vue";

import router from "@/router";
import store from "@/store";
import { useRoute } from "vue-router";
import { FilterScopeEnums } from "@/store/modules/filters/types";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import DeviceDetails from "@/components/devices/tabs/DeviceDetails.vue";
import DeviceUtilizationEvents from "@/components/devices/tabs/DeviceUtilizationEvents.vue";
import DeviceUtilizationSummary from "@/components/devices/tabs/DeviceUtilizationSummary.vue";

export default defineComponent({
  name: "ViewDeviceNew",
  components: {
    DeviceUtilizationSummary,
    DeviceUtilizationEvents,
    DeviceDetails,
    BaseIcon,
    Filters,
    BaseLayout,
  },

  methods: {
    changeData() {
      // console.log("<<<<< filter changed >>>>>>");
    },
    dateUpdated() {
      const route = useRoute();
      const deviceId = route.params.id;
      store.dispatch("analytics/getAnalyticsUtilizationRates", {
        device_id: deviceId,
      });
    },
  },
  setup(props) {
    const isOpen = ref(false);
    const deviceTabIndex = ref(0);
    const route = useRoute();

    const deviceViewTabs = [
      "Device Details",
      "Utilization events",
      "Utilization summary",
    ];

    const allowedFilters = [
      FilterScopeEnums.SUBCATEGORY,
      FilterScopeEnums.VENDOR,
      FilterScopeEnums.RECONCILIATION_STATUSES,
    ];

    const dateFrom = computed(() => {
      return store.getters["filters/dateFrom"];
    });

    const dateTo = computed(() => {
      return store.getters["filters/dateTo"];
    });

    onMounted(async () => {
      const deviceId = route.params.id;

      await Promise.all([
        store.dispatch("devices/getDevice", deviceId),
        store.dispatch("devices/getReconciliationDeviceEvents", deviceId),
        store.dispatch("devices/getDeviceEvents", deviceId),
        store.dispatch("devices/getDeviceFiles", {
          device_id: deviceId,
          reset: true,
        }),

        store.dispatch("analytics/getAnalyticsUtilizationRates", {
          device_id: deviceId,
          date_from: dateFrom.value,
          date_to: dateTo.value,
        }),
      ]);
    });

    const device = computed(() => {
      return store.getters["devices/device"];
    });

    const openDevice = (device) => {
      isOpen.value = true;
      store.commit("devices/SET_DEVICE", device);
      router.push({ name: "ViewDevice", params: { id: device.id } });
    };

    const closeDevice = () => {
      isOpen.value = false;
      router.push({ name: "Devices" });
    };

    const changeTabIndex = (index) => {
      deviceTabIndex.value = index;
    };

    const updateTable = (res) => {
      store.dispatch("devices/getDevices", res);
    };

    return {
      device,
      isOpen,
      deviceViewTabs,
      allowedFilters,
      openDevice,
      closeDevice,
      changeTabIndex,
      updateTable,
      deviceTabIndex,
    };
  },
});
</script>
