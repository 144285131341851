import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { FindDevicesState } from "@/store/modules/find-devices/types";

export const getters: GetterTree<FindDevicesState, RootState> = {
  devicesList(state: FindDevicesState) {
    return state.devicesList;
  },
  devicesDumpList(state: FindDevicesState) {
    return state.devicesDumpList;
  },
  showAssignToPatient(state: FindDevicesState) {
    return state.showAssignToPatient;
  },
  showAssignToClinician(state: FindDevicesState) {
    return state.showAssignToClinician;
  },
  showMarkAsDirty(state: FindDevicesState) {
    return state.showMarkAsDirty;
  },
  showMarkAsAvailable(state: FindDevicesState) {
    return state.showMarkAsAvailable;
  },
  showSubmitRepairTicket(state: FindDevicesState) {
    return state.showSubmitRepairTicket;
  },
  currentDevice(state: FindDevicesState) {
    return state.currentDevice;
  },
  selectedItem(state: FindDevicesState) {
    return state.selectedItem;
  },
  selectedDevice(state: FindDevicesState) {
    return state.selectedDevice;
  },
  tagOption(state: FindDevicesState) {
    return state.tagOption;
  },
};
