<template>
  <BaseLayout>
    <Filters :allowedFilters="allowedFilters" @apply_filters="changeData" />
    <div class="dashboard__stats">
      <div
        :key="key"
        v-for="(scope, key) in data"
        class="dashboard__stats__item dashboard__card"
      >
        <div class="dashboard__stats__item__see__more">
          <button
            @click="goToDeviceView(scope)"
            class="dashboard__stats__item__see__more__button"
          >
            <span>See more </span>
            <BaseIcon icon="see-more" />
            <!--            <BaseIcon icon="see-more" />-->
          </button>
        </div>

        <div class="dashboard__charts">
          <div class="dashboard__charts__item">
            <div class="dashboard__charts__item__head">
              <div class="dashboard__charts__item__title">
                <h3 class="header-3">{{ scope.name }}</h3>
                <h5 class="body-2">Active devices</h5>
              </div>
              <div class="dashboard__charts__item__select">
                <div class="dashboard__item__select_values">
                  <span
                    :class="{ active: group === scope.groupBy }"
                    v-for="(group, key) in groupByList"
                    :key="key"
                    >{{ group }}</span
                  >
                </div>
              </div>
            </div>
            <div class="dashboard__charts__line">
              <MultiLineChart
                :chart-options="lineChartOptions"
                :chart-data="scope.lineChart"
                :width="700"
              />
            </div>
          </div>
          <div class="dashboard__charts__item">
            <div class="dashboard__charts__item__head">
              <div class="dashboard__charts__item__title">
                <h5 @click="goToDeviceView(scope)" class="action body-2">
                  Total cost of ownership
                </h5>
                <h3 @click="goToDeviceView(scope)" class="action header-2">
                  {{ scope.totalCost }}
                </h3>
              </div>
            </div>
            <div class="dashboard__charts__pie">
              <DoughnutChart
                :is-filterable="true"
                @filter="addStatusToFilter($event, scope)"
                :chartData="scope.doughnutChart"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import BaseLayout from "@/components/layout/BaseLayout.vue";
import MultiLineChart from "@/components/resusables/charts/MultiLineChart.vue";
import Filters from "@/components/resusables/filters/Filters.vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import store from "@/store";
import DoughnutChart from "@/components/resusables/charts/DoughnutChart.vue";
import { FilterScopeEnums } from "@/store/modules/filters/types";
import router from "@/router";

export default defineComponent({
  name: "DashboardPage",
  components: {
    DoughnutChart,
    BaseIcon,
    Filters,
    MultiLineChart,
    BaseLayout,
  },
  setup() {
    const allowedFilters = [FilterScopeEnums.VENDOR];

    const groupByList = ["day", "month", "week"];

    onMounted(() => {
      store.dispatch("devices/getDepartments", { hideProgressBar: true });
      store.dispatch("devices/getVendors", { hideProgressBar: true });
      store.dispatch("devices/getHospitals", { hideProgressBar: true });
      getDashboardData();
    });

    const lineChartOptions = {
      // responsive: true,
      maintainAspectRatio: false,
      scaleShowVerticalLines: false,

      interaction: {
        mode: "index",
        intersect: false,
      },
      stacked: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
          text: "",
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          type: "linear",
          display: true,
          position: "left",
          text: "Your Title",
          grace: "15%",
          beginAtZero: true,
          min: 0,
          ticks: {
            stepSize: 10,
          },
        },
        y1: {
          type: "linear",
          display: false,
          position: "right",
          // grid line settings
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
      },
    };

    const getDashboardData = async () => {
      store.dispatch("dashboard/getDashboardData");
    };

    const changeData = async () => {
      await getDashboardData();
    };

    const data = computed(() => {
      return store.getters["dashboard/data"];
    });

    const filters = computed(() => {
      return store.getters["filters/filters"];
    });

    const goToDeviceView = (scopeItem: any) => {
      const val = { ...scopeItem.scopeValue };
      if (scopeItem.scope === FilterScopeEnums.ORGANIZATION) {
        filters.value["department"] = val;
      } else if (scopeItem.scope === FilterScopeEnums.DEPARTMENT) {
        filters.value["category"] = val;
      } else if (scopeItem.scope === FilterScopeEnums.CATEGORY) {
        filters.value["subCategory"] = val;
      }

      store.commit("filters/SET_FILTERS", filters.value);

      router.push("/devices");
    };
    const addStatusToFilter = (status: string, scopeItem: any) => {
      const val = { ...scopeItem.scopeValue };
      if (scopeItem.scope === FilterScopeEnums.ORGANIZATION) {
        filters.value["department"] = val;
      } else if (scopeItem.scope === FilterScopeEnums.DEPARTMENT) {
        filters.value["category"] = val;
      } else if (scopeItem.scope === FilterScopeEnums.CATEGORY) {
        filters.value["subCategory"] = val;
      }
      filters.value.deviceStatuses = status;
      store.commit("filters/SET_SELECTED_FILTER", filters.value);
      router.push("/devices");
    };

    return {
      data,
      goToDeviceView,
      changeData,
      allowedFilters,
      groupByList,
      lineChartOptions,
      addStatusToFilter,
    };
  },
});
</script>
