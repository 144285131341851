import { MutationTree } from "vuex";
import { DropdownState } from "@/store/modules/dropdowns/types";

export const mutations: MutationTree<DropdownState> = {
  TOGGLE_DROPDOWN(state: DropdownState, dropdown: string): boolean {
    state[dropdown] = !state[dropdown];
    return state[dropdown];
  },
  CLOSE_DROPDOWN(state: DropdownState, dropdown: string): boolean {
    state[dropdown] = false;
    return state[dropdown];
  },
  CLOSE_ALL_DROPDOWN(state: DropdownState): DropdownState {
    state.header_user = false;
    state.header_notification = false;
    state.access_points_list = false;
    state.update_beacon_voltage = false;
    // state.access_point_list_item = -1;
    state.device_location_list_item = -1;
    state.filters = false;
    return state;
  },
  TOGGLE_ACCESS_POINT_LIST_ITEM(state: DropdownState, index: number): number {
    if (index === state.access_point_list_item) {
      state.access_point_list_item = -1;
    } else {
      state.access_point_list_item = index;
    }
    return state.access_point_list_item;
  },
  TOGGLE_DEVICE_LOCATION_LIST_ITEM(
    state: DropdownState,
    index: number
  ): number {
    if (index === state.device_location_list_item) {
      state.device_location_list_item = -1;
    } else {
      state.device_location_list_item = index;
    }
    return state.device_location_list_item;
  },
};
