import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { DropdownState } from "@/store/modules/dropdowns/types";

export const getters: GetterTree<DropdownState, RootState> = {
  headerUser(state: DropdownState): boolean {
    return state.header_user;
  },
  filters(state: DropdownState): boolean {
    return state.filters;
  },
  headerNotification(state: DropdownState): boolean {
    return state.header_notification;
  },
  accessPointsList(state: DropdownState): boolean {
    return state.access_points_list;
  },
  updateBeaconVoltage(state: DropdownState): boolean {
    return state.update_beacon_voltage;
  },
  accessPointListItem(state: DropdownState): number {
    return state.access_point_list_item;
  },
  deviceLocationListItem(state: DropdownState): number {
    return state.device_location_list_item;
  },
};
