<template>
  <ChartLine
    class="line-chart"
    :chart-data="chartData"
    :chart-options="chartOptions"
  />
</template>

<script>
import { Line as ChartLine } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement
);

export default {
  name: "MultiLineChart",
  components: { ChartLine },
  props: {
    width: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number,
      default: 300,
    },
    chartData: {
      type: Object,
      required: false,
      default: () => ({
        labels: [
          "March 4",
          "March 6",
          "March 8",
          "March 10",
          "March 12",
          "March 14",
          "March 16",
        ],
        datasets: [
          {
            label: "Dataset 1",
            data: [130, 145, 130, 125, 125, 130, 135, 138, 140, 145, 150, 147],
            borderColor: "#000000",
            backgroundColor: "#000000",
            yAxisID: "y",
          },
          {
            label: "Dataset 2",
            data: [50, 60, 55, 60, 70, 80, 70, 60, 64, 69, 65, 60],
            borderColor: "#016667",
            backgroundColor: "#016667",
            yAxisID: "y",
          },
          {
            label: "Dataset 3",
            data: [65, 50, 47, 45, 40, 45, 40, 35, 40, 45, 50, 45],
            borderColor: "#A9CDCD",
            backgroundColor: "#A9CDCD",
            yAxisID: "y",
          },
          {
            label: "Dataset 4",
            data: [45, 40, 43, 40, 35, 30, 30, 35, 30, 35, 30, 30],
            borderColor: "#E9B32A",
            backgroundColor: "#E9B32A",
            yAxisID: "y",
          },
          {
            label: "Dataset 5",
            data: [20, 25, 23, 20, 15, 20, 10, 15, 20, 15, 20, 15],
            borderColor: "#D0E2E2",
            backgroundColor: "#D0E2E2",
            yAxisID: "y",
          },
        ],
      }),
    },
    chartOptions: {
      type: Object,
      default: () => ({
        // responsive: true,
        maintainAspectRatio: false,
        scaleShowVerticalLines: false,

        interaction: {
          mode: "index",
          intersect: false,
        },
        stacked: false,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
            text: "",
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            type: "linear",
            display: true,
            position: "left",
            text: "Your Title",
            grace: "15%",
            beginAtZero: true,
            min: 0,
            ticks: {
              stepSize: 5,
            },
          },
          y1: {
            type: "linear",
            display: false,
            position: "right",
            // grid line settings
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
          },
        },
      }),
    },
  },
};
</script>
